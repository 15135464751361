import React, { useState } from 'react'
import styled from 'styled-components'
import FsLightbox from 'fslightbox-react'

import gp1 from '../assets/images/gallery/preview/1.jpg'
import gp2 from '../assets/images/gallery/preview/2.jpg'
import gp3 from '../assets/images/gallery/preview/3.jpg'
import gp4 from '../assets/images/gallery/preview/4.jpg'
import gp5 from '../assets/images/gallery/preview/5.jpg'

import g1 from '../assets/images/gallery/1.jpg'
import g2 from '../assets/images/gallery/2.jpg'
import g3 from '../assets/images/gallery/3.jpg'
import g4 from '../assets/images/gallery/4.jpg'
import g5 from '../assets/images/gallery/5.jpg'
import g6 from '../assets/images/gallery/6.jpg'
import g7 from '../assets/images/gallery/7.jpg'
import g8 from '../assets/images/gallery/8.jpg'
import g9 from '../assets/images/gallery/9.jpg'
import g10 from '../assets/images/gallery/10.jpg'
import g11 from '../assets/images/gallery/11.jpg'

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--white);
    text-align: center;
    margin: 0 0 5rem 0;
  }
`

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 0 2rem 0;

  div {
    box-sizing: border-box;
    overflow: hidden;
    width: 50%;
    height: 24rem;
    max-height: 24rem;
    padding: 1rem;
    cursor: pointer;

    div {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    @media (max-width: 576px) {
      width: 100%;
      padding: 1rem 0;
    }

    @media (min-width: 768px) {
      width: 33%;
      padding: 1rem;
    }

    @media (min-width: 992px) {
      width: 20%;
      padding: 2rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`

const gallery = [g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11]
const preview = [gp1, gp2, gp3, gp4, gp5]

const Gallery = () => {
  const [open, setOpen] = useState(false)
  const [slide, setSlide] = useState(1)

  const onLightbox = (i: number) => {
    setSlide(i)
    setOpen(!open)
  }

  return (
    <GalleryContainer>
      <FsLightbox toggler={open} sources={gallery} slide={slide} />
      <h1>Оцените наш уют</h1>
      <GalleryWrapper>
        {preview.map((image, i) => (
          <div key={i} onClick={() => onLightbox(i + 1)}>
            <div>
              <img src={image} />
            </div>
          </div>
        ))}
      </GalleryWrapper>
    </GalleryContainer>
  )
}

export default Gallery
