import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

type Props = {
  href: string
  children: string
  dark?: boolean
}

const DivStyled = styled.div`
  display: flex;

  a {
    padding: 2rem 5rem 2rem 5rem;
    background-color: var(--yellow);
    color: var(--black);
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    transition: all 0.3s;

    &:hover {
      box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.1);
    }

    .dark {
      &:hover {
        box-shadow: 7px 7px 0 0 rgba(255, 255, 255, 0.2);
      }
    }
  }
`

const DivStyledDark = styled.div`
  display: flex;

  a {
    padding: 2rem 5rem 2rem 5rem;
    background-color: var(--yellow);
    color: var(--black);
    text-align: center;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    transition: all 0.3s;

    @media (max-width: 576px) {
      padding: 2rem 3rem;
    }

    &:hover {
      box-shadow: 7px 7px 0 0 rgba(255, 255, 255, 0.2);
    }
  }
`

const ButtonLink = ({ href, children, dark }: Props) => {
  const isExternal = href?.startsWith('http')

  const Button = () => {
    return isExternal ? (
      <a href={href} target="_blank">
        {children}
      </a>
    ) : (
      <Link to={href}>{children}</Link>
    )
  }

  return dark ? (
    <DivStyledDark>
      <Button />
    </DivStyledDark>
  ) : (
    <DivStyled>
      <Button />
    </DivStyled>
  )
}

export default ButtonLink
